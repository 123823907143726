import React, { useState, useEffect } from 'react';
import './styles.css';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification, onAuthStateChanged } from 'firebase/auth';
import SignIn from './components/SignIn';
import ForgotPasswordModal from './components/ForgotPasswordModal';
import ResendVerificationModal from './components/ResendVerificationModal';
import { child, get, getDatabase, ref, update, onValue, remove } from 'firebase/database';

function App() {
    const firebaseConfig = {
        apiKey: "AIzaSyCfI8eKrUUr0xvvkjdnEmQp90bddar6fAo",
        authDomain: "venuerush.firebaseapp.com",
        databaseURL: "https://venuerush-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "venuerush",
        storageBucket: "venuerush.appspot.com",
        messagingSenderId: "375492774655",
        appId: "1:375492774655:web:ac542141b1e1297083daca"
    };

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const db = getDatabase(app);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const [timer, setTimer] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(true);
    const [isSignedIn, setIsSignedIn] = useState(true);
    const [showVerificationModal, setShowVerificationModal] = useState(false);

    const [newFacility, setNewFacility] = useState({ name: '', id: '', status: 'Available' });
    const [community, setCommunity] = useState('');
    const [communityMetadata, setCommunityMetadata] = useState('');
    const [facilities, setFacilities] = useState([]);
    const [loadFacilitiesData, setLoadFacilitiesData] = useState(false);

    // Handle user sign-in
    const handleSubmit = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                if (!user.emailVerified) {
                    setIsEmailVerified(false);
                    setShowVerificationModal(true);
                } else {
                    setIsEmailVerified(true);
                    setShowVerificationModal(false);
                }
            })
            .catch((error) => {
                console.error(error.code, error.message);
            });
    };

    // Handle forgot password
    const handleForgotPassword = (e) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, resetEmail)
            .then(() => {
                alert("Password reset email sent!");
                setIsButtonDisabled(true);
                setTimer(60);
            })
            .catch((error) => {
                console.error(error.code, error.message);
            });
    };

    // Resend verification email
    const handleResendVerificationEmail = () => {
        const user = auth.currentUser;
        sendEmailVerification(user)
            .then(() => {
                alert('Verification email sent! Please check your inbox.');
                setIsButtonDisabled(true);
                setTimer(60);
                // Check the email verification status after resending
                user.reload().then(() => {
                    if (user.emailVerified) {
                        setIsEmailVerified(true);
                        setShowVerificationModal(false);
                    } else {
                        setIsEmailVerified(false);
                        setShowVerificationModal(true);
                    }
                });
            })
            .catch((error) => {
                console.error('Error sending verification email:', error);
            });
    };

    // Countdown for button re-enable
    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(countdown);
        } else {
            setIsButtonDisabled(false);
        }
    }, [timer]);

    // Monitor auth state and email verification status
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsSignedIn(true);
                setEmail(user.email);
                setIsEmailVerified(user.emailVerified);
                if (user.emailVerified) {
                    setShowVerificationModal(false);
                } else {
                    setShowVerificationModal(true);
                }
            } else {
                setIsSignedIn(false);
                setShowVerificationModal(false);
            }
        });
        return () => unsubscribe();
    }, [auth]);

    // Retrieve community using email
    useEffect(() => {
        if (email) {
            get(child(ref(db, '/admins/'), btoa(email)))
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        setCommunity(snapshot.val());
                    } else {
                        console.log('Error retrieving community from email');
                    }
                })
                .catch((error) => {
                    console.error(error.message, error.code);
                });
        }
    }, [email, db]);

    // Retrieve community metadata
    useEffect(() => {
        if (community && email) {
            get(ref(db, `/communities/${community}/metadata`))
                .then((snapshot) => {
                    const metadata = snapshot.val();
                    setCommunityMetadata(metadata);
                    setLoadFacilitiesData(true);
                })
                .catch((error) => {
                    console.error(error.message, error.code);
                });
        }
    }, [community, email, db]);

    // Retrieve and render facilities
    useEffect(() => {
        if (loadFacilitiesData && community) {
            const facilitiesRef = ref(db, `/communities/${community}/facilities`);
            onValue(facilitiesRef, (snapshot) => {
                const facilitiesData = snapshot.val();
                if (facilitiesData) {
                    const facilitiesArray = Object.keys(facilitiesData).map(key => ({
                        id: key,
                        ...facilitiesData[key]
                    }));
                    setFacilities(facilitiesArray);
                }
            });
        }
    }, [loadFacilitiesData, community, db]);

    // Add a new facility
    function addNewFacility(e) {
        e.preventDefault();
        const facilityId = newFacility.id.trim();
        // const facilityName = newFacility.name.trim();

        if (!facilityId) {
            alert('Please provide a facility name');
            return;
        }

        update(
            child(ref(db, `/communities/${community}/facilities/`), facilityId),
            {
                name: newFacility.name,
                id: newFacility.id,
                status: 'Available',
                lastUpdated: Date.now(),
            }
        )
            .then(() => {
                alert('Facility added successfully!');
                setNewFacility({ name: '', id: '', status: 'Available' });
            })
            .catch((error) => {
                console.error(error.message, error.code);
                alert('Failed to add facility');
            });
    }

    const [showDeleteModal, setShowDeleteModal] = useState(false);
const [facilityToDelete, setFacilityToDelete] = useState(null);

// Trigger modal on delete click
const handleDeleteClick = (facility) => {
    setFacilityToDelete(facility);
    setShowDeleteModal(true);
};
const handleChangeFacilityStatus = (facility) => {
    let newStatus;

    // Toggle the status between 'Available' and 'Occupied'
    if (facility.status === 'Available') {
        newStatus = 'Occupied';
    } else {
        newStatus = 'Available';
    }

    // Ensure facility.id or facility.key is passed as the child path
    update(
        ref(db, `/communities/${communityMetadata.code}/facilities/${facility.id}`), // Make sure to use facility.id or a similar identifier, not the entire facility object
        {
            status: newStatus
        }
    )
    .then(() => {
        console.log('Status updated successfully');
    })
    .catch((error) => {
        console.error('Error updating status:', error);
    });
};

// Confirm deletion
const confirmDelete = () => {
    if (facilityToDelete) {
        const facilityRef = ref(db, `/communities/${community}/facilities/${facilityToDelete.id}`);
        remove(facilityRef)
            .then(() => {
                alert('Facility deleted successfully!');
                setShowDeleteModal(false);
            })
            .catch((error) => {
                console.error('Error deleting facility:', error);
            });
    }
};



    return (
        <div id="container">
            <div id="sign-in-container">
                {!isSignedIn && (
                    <SignIn
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        setPassword={setPassword}
                        handleSubmit={handleSubmit}
                        setIsForgotPassword={setIsForgotPassword}
                    />
                )}

                {isForgotPassword && (
                    <ForgotPasswordModal
                        resetEmail={resetEmail}
                        setResetEmail={setResetEmail}
                        handleForgotPassword={handleForgotPassword}
                        isButtonDisabled={isButtonDisabled}
                        timer={timer}
                        onClose={() => setIsForgotPassword(false)}
                    />
                )}

                {!isEmailVerified && isSignedIn && showVerificationModal && (
                    <ResendVerificationModal
                        handleResendVerificationEmail={handleResendVerificationEmail}
                        isButtonDisabled={isButtonDisabled}
                        timer={timer}
                    />
                )}
            </div>

            <div id="create-dashboard-container">
                {isSignedIn && isEmailVerified && (
                    <div className="min-h-screen bg-gray-300 p-8">
                        <h1 className="font-mono text-5xl font-semibold mb-4 text-center text-blue-600">VenueRush</h1>
                        <h1 className="text-3xl font-bold mb-4 text-center">Admin Dashboard</h1>
                        <h1 className="text-xl font-bold text-black">Community Name: <span className="font-medium">{communityMetadata.name}</span></h1>
                        <h1 className="text-xl font-bold text-black">Community Code: <span className="font-medium">{communityMetadata.code}</span></h1>
                        <h1 className='text-xl font-bold text-black'>Community Admin: <span className='font-medium'>{communityMetadata.admin}</span></h1>

                        <div className="mb-8 p-6 bg-white rounded shadow-md py-5 mt-6">
                            <h2 className="text-xl font-semibold mb-4">Add New Facility</h2>
                            <form onSubmit={addNewFacility}>
                                <input
                                    type="text"
                                    placeholder="Facility Name"
                                    name="facility-name"
                                    value={newFacility.name}
                                    onChange={(e) => setNewFacility({ ...newFacility, name: e.target.value })}
                                    className="w-full md:w-1/3 p-2 border rounded"
                                />
                                <input
                                    type="text"
                                    placeholder="Database Id"
                                    name="facility-id"
                                    value={newFacility.id}
                                    onChange={(e) => setNewFacility({ ...newFacility, id: e.target.value })}
                                    className="w-full md:w-1/3 p-2 border rounded ml-4"
                                />
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded ml-8">
                                    Add Facility
                                </button>
                            </form>
                        </div>
                        
                        <div className="bg-white rounded shadow-md p-6">
                            <h2 className="text-xl font-semibold mb-4">Existing Facilities</h2>
                            <table className="min-w-full text-left table-auto border-collapse border border-black">
                                <thead>
                                    <tr className="border border-black">
                                        <th className="px-4 py-2 border border-black font-extrabold">Facility</th>
                                        <th className="px-4 py-2 border border-black font-extrabold">Database Id</th>
                                        <th className="px-4 py-2 border border-black font-extrabold">Status</th>
                                        <th className="px-4 py-2 border border-black font-extrabold">Last Updated</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {facilities.map((facility) => (
                                        <tr key={facility.id} className="border border-black">
                                            <td className="px-4 py-2 border border-black font-mono">{facility.name}</td>
                                            <td className="px-4 py-2 border border-black font-mono">{facility.id}</td>
                                            <td
                                                className={`px-4 py-2 border  font-semibold border-black font-mono ${
                                                    facility.status === 'Occupied' ? 'text-red-500' : 
                                                    facility.status === 'Available' ? 'text-green-500' : 
                                                    'text-yellow-800'
                                                }`}
                                            >
                                                {facility.status}
                                            </td>
                                            <td className="px-4 py-2 border border-black italic">
                                                {new Date(facility.lastUpdated).toLocaleString()}
                                            </td>
                                            <td className="px-4 py-2 border border-black">
                                                <button
                                                    onClick={() => handleDeleteClick(facility)}
                                                    className="bg-red-500 text-white px-4 py-2 rounded"
                                                >
                                                    Delete
                                                </button>
                                                <button
                                                    onClick={() => handleChangeFacilityStatus(facility)}
                                                    className='bg-blue-700 text-white px-4 py-2 rounded pl-4 ml-4'
                                                >Update</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>

                            {showDeleteModal && (
                                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                    <div className='bg-blue-400 p-6 rounded shadow-xl text-center'>
                                        <div className="bg-white p-6 rounded shadow-md text-center">
                                            <p className="text-gray-700 mb-4 text-2xl">Are you sure you want to delete this facility?</p>
                                            <p className='text-gray-900 mb-4 font-semibold font-mono'>Action is Irreversible & Permanent!</p>
                                            <div className="flex justify-center space-x-4">
                                                <button
                                                    onClick={confirmDelete}
                                                    className="bg-red-500  text-white px-4 py-2 rounded"
                                                >
                                                    Yes, I'm sure
                                                </button>
                                                <button
                                                    onClick={() => setShowDeleteModal(false)}
                                                    className="bg-green-400 text-black px-4 py-2 rounded"
                                                >
                                                    No, cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default App;
