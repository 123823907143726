// ResendVerificationModal.js
import React from 'react';

function ResendVerificationModal({ handleResendVerificationEmail, isButtonDisabled, timer, onClose }) {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
  <div className="bg-white rounded-lg p-6 w-full max-w-sm shadow-lg">
    <h2 className="text-xl font-semibold text-center mb-4">Email Verification</h2>
    <p className="text-center text-gray-600 mb-4">
      <b>Please verify your email to continue.</b>
    </p>
    <p className="text-center text-gray-600 mb-4">
        If you have not received the email after 45 seconds, please check SPAM/JUNK folder. Refresh after verifying the link.
    </p>
    <button
      className={`w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300 ${
        isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      onClick={handleResendVerificationEmail}
      disabled={isButtonDisabled}
    >
      {isButtonDisabled ? `Resend Email (${timer}s)` : 'Resend Verification Email'}
    </button>

  </div>
</div>


    );
}

export default ResendVerificationModal;
