// ForgotPasswordModal.js
import React from 'react';

function ForgotPasswordModal({ resetEmail, setResetEmail, handleForgotPassword, isButtonDisabled, timer, onClose }) {
    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                <h2 className="text-xl font-bold mb-4">Reset Password</h2>
                <form onSubmit={handleForgotPassword}>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="resetEmail">Email</label>
                    <input
                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="resetEmail"
                        type="email"
                        placeholder="Enter your email"
                        value={resetEmail}
                        onChange={(e) => setResetEmail(e.target.value)}
                    />
                    <div className="mt-4 flex justify-between items-center">
                        <button
                            type="submit"
                            className={`font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${isButtonDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700 text-white'}`}
                            disabled={isButtonDisabled}
                        >
                            {isButtonDisabled ? 'Please wait...' : 'Send Reset Email'}
                        </button>
                        {isButtonDisabled && (
                            <span className="text-red-500 font-bold text-sm">Resend in {timer}s</span>
                        )}
                        <button
                            className="text-gray-600 hover:text-gray-900"
                            onClick={onClose}
                        >
                            OK
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ForgotPasswordModal;
