// components/SignIn.js
import React from "react";

const SignIn = ({ email, setEmail, password, setPassword, handleSubmit, setIsForgotPassword }) => {
    return (
        <div className='bg-slate-400'>
            <div className="min-h-screen flex items-center justify-center">
                <div className="bg-gray-200 p-8 rounded-lg shadow-lg w-full max-w-sm">
                    <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">Sign In</h2>
                    <h3 className="text-2xl font-semibold text-center text-gray-800 mb-6">~~~ VenueRush ~~~</h3>

                    <form id="sign-in" onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
                            <input
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="email"
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mb-6">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">Password</label>
                            <input
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                id="password"
                                type="password"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="flex items-center justify-between">
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="submit"
                            >
                                Sign In
                            </button>
                            <a
                                className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800 cursor-pointer"
                                onClick={() => setIsForgotPassword(true)}
                            >
                                Forgot Password?
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
